<script setup lang="ts">
import Close from '@base/assets/icons/close.svg?raw';

const specialsStore = useSpecialsStore();

const show = ref(!!specialsStore.banner);

const close = () => {
    show.value = false;
};
</script>

<template>
    <div v-if="specialsStore.banner && show" class="specials-banner">
        <NuxtLink v-if="specialsStore.bannerUrl" :to="specialsStore.bannerUrl">
            <p>{{ specialsStore.banner }}</p>
        </NuxtLink>

        <p v-else>{{ specialsStore.banner }}</p>

        <button
            title="Close specials banner"
            type="button"
            class="close-button"
            @click="close"
        >
            <BaseIcon :icon-html="Close" class="close-icon" />
        </button>
    </div>
</template>

<style lang="postcss" scoped>
.specials-banner {
    position: relative;
    background-color: var(--color-accent);
    color: var(--color-text-on-accent);
    font-size: 1.5rem;
    text-align: center;
    z-index: 13; /* 1 less than NavSubMenu.vue */

    & p {
        display: block;
        height: 100%;
        margin: 0;
        padding: 1rem 0;
    }

    & a {
        display: block;
        height: 100%;
        color: var(--color-text-on-accent);
        padding: 1rem 0;

        & p {
            padding: 0;
        }
    }

    & .close-button {
        position: absolute;
        bottom: -1rem;
        right: 3rem;
        background-color: black;
        border-radius: 100%;
        height: 2rem;
        width: 2rem;
        border: 1px solid transparent;
        padding: 0;
        cursor: pointer;
    }

    & .close-icon {
        --base-icon-size: 1.5rem;
        --base-icon-fill: white;
        --base-icon-stroke: white;

        transition: var(--transition-base);
        margin: 0 auto;

        &:hover {
            --base-icon-fill: var(--color-accent);
            --base-icon-stroke: var(--color-accent);
        }
    }
}
</style>
