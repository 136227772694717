export const headerLinks: ChapmanLink[] = [
    {
        display: 'Home',
        link: { name: 'index' },
    },
    {
        display: 'Shop New',
        link: { name: 'search-type-make-model', params: { type: 'new' } },
        children: [
            // {
            //     display: `${variables.clearanceOptions.year} Model Year Blowout`,
            //     link: { name: 'model-year-blowout' },
            // },
            {
                display: 'Custom Orders',
                link: { name: 'form-vehicle-custom-order' },
            },
            {
                display: 'New Car Specials',
                link: { name: 'specials-type', params: { type: 'new' } },
            },
            {
                display: '$500 Military Bonus Cash',
                link: { name: 'military-bonus-cash' },
            },
            {
                display: 'Automobility Disability Assistance',
                link: { name: 'automobility-disability-assistance' },
            },
        ],
    },
    {
        display: 'Shop Used',
        link: { name: 'search-type-make-model', params: { type: 'used' } },
        children: [
            {
                display: 'Used Car Weekly Deals',
                link: { name: 'specials-type', params: { type: 'used' } },
            },
            {
                display: 'Shop Certified',
                link: {
                    name: 'search-type-make-model',
                    params: { type: 'cpo' },
                },
            },
            {
                display: 'Certified Specials',
                link: { name: 'specials-type', params: { type: 'cpo' } },
            },
            {
                display: 'Certified Pre-Owned Details',
                link: { name: 'certified-preowned-details' },
            },
        ],
    },
    {
        display: 'Sell/Trade',
        link: { name: 'sell-my-car' },
    },
    {
        display: 'Financing',
        link: { name: 'form-finance' },
    },
    {
        display: 'Parts & Service',
        link: {},
        children: [
            {
                display: 'Schedule Service',
                link: { name: 'service' },
            },
            {
                display: 'Browse Service Coupons',
                link: { name: 'service-coupons' },
            },
            {
                display: 'Jeep Wave Program',
                link: { name: 'service-jeep-wave' },
            },
            {
                display: 'MOPAR Vehicle Protection',
                link: { name: 'service-protection' },
            },
            {
                display: 'MOPAR Express Lane',
                link: { name: 'service-express' },
            },
            {
                display: 'Service & Repair Financing',
                link: { name: 'service-and-repair-financing' },
            },
            {
                display: 'Order Parts Online',
                link: { name: 'parts' },
            },
            {
                display: 'Parts Catalog',
                link: { name: 'parts-catalog' },
            },
            {
                display: 'Collision Center',
                link: 'https://www.chapmancollision.com/locations/chapman-collision-yuma',
            },
        ],
    },
    {
        display: 'Info',
        link: {},
        children: [
            {
                display: 'Directions & Hours',
                link: { name: 'contact' },
            },
            {
                display: 'Careers',
                link: { name: 'careers' },
            },
            {
                display: 'Dealership Reviews',
                link: { name: 'reviews' },
            },
            {
                display: 'Staff Directory',
                link: { name: 'staff-directory' },
            },
            {
                display: 'Community',
                link: { name: 'community' },
            },
            {
                display: 'Privacy Policy',
                link: { name: 'privacy-policy' },
            },
            {
                display: 'Sitemap',
                link: { name: 'sitemap' },
            },
        ],
    },
];

export const footerLinks: ChapmanLink[] = [
    {
        display: 'Home',
        link: { name: 'index' },
    },
    {
        display: 'New Inventory',
        link: { name: 'search-type-make-model', params: { type: 'new' } },
    },
    {
        display: 'Used Car Inventory',
        link: { name: 'search-type-make-model', params: { type: 'used' } },
    },
    {
        display: 'Certified Vehicles',
        link: { name: 'search-type-make-model', params: { type: 'cpo' } },
    },
    {
        display: 'Sell/Trade',
        link: { name: 'sell-my-car' },
    },
    {
        display: 'Finance',
        link: { name: 'form-finance' },
    },
    {
        display: 'Service',
        link: { name: 'service' },
    },
    {
        display: 'Service & Repair Financing',
        link: { name: 'service-and-repair-financing' },
    },
    {
        display: 'Parts',
        link: { name: 'parts' },
    },
    {
        display: 'Directions',
        link: { name: 'contact' },
    },
    {
        display: 'Contact Us',
        link: { name: 'contact' },
    },
    {
        display: 'Careers',
        link: { name: 'careers' },
    },
    {
        display: 'Community',
        link: { name: 'community' },
    },
    {
        display: 'Reviews',
        link: { name: 'reviews' },
    },
    {
        display: 'Privacy Policy',
        link: { name: 'privacy-policy' },
    },
    {
        display: 'Sitemap',
        link: { name: 'sitemap' },
    },
];

export const footerMembers: ChapmanLink[] = [
    {
        display: 'Chapman Choice',
        link: 'https://www.chapmanchoice.com',
    },
    {
        display: 'Chapman Arizona',
        link: 'https://www.chapmanaz.com',
    },
    {
        display: 'Used Cars Phoenix',
        link: 'https://www.chapmanaz.com/search/used/cars/phoenix',
    },
    {
        display: 'Chapman Yuma',
        link: 'https://www.chapmanyuma.com/',
    },
];
