<script setup lang="ts">
const cookieKey = 'accepted-cookies';

const show = ref(false);

const accept = () => {
    try {
        localStorage.setItem(cookieKey, 'true');
        show.value = false;
    } catch (err) {
        handleLocalStorageError(err);
        show.value = false;
    }
};

onMounted(() => {
    try {
        const acceptedCookie = localStorage.getItem(cookieKey);
        if (!acceptedCookie) show.value = true;
    } catch (err) {
        handleLocalStorageError(err);
        show.value = true;
    }
});
</script>

<template>
    <Transition name="slide">
        <div v-if="show" class="cookie-notice">
            <div class="center-constraint">
                <p>
                    We use cookies and browser activity to improve your
                    experience, personalize content and ads, and analyze how our
                    sites are used. For more information, view our
                    <NuxtLink to="privacy-policy" class="link">
                        Privacy Policy
                    </NuxtLink>
                    .
                </p>

                <BaseButton>
                    <button type="button" @click="accept">
                        <span>Accept</span>
                    </button>
                </BaseButton>
            </div>
        </div>
    </Transition>
</template>

<style lang="postcss" scoped>
.cookie-notice {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    padding: 1rem;
    background-color: #454545;
    color: var(--color-font-light);

    & .center-constraint {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1500px;
        margin: 0 auto;
    }

    & p {
        margin: 0;
    }

    & .link {
        color: var(--color-font-light);
    }

    & .base-button {
        margin-left: 2rem;
    }
}

.slide-enter-active {
    transition: all 0.8s ease;
}

.slide-leave-active {
    transition: all 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateY(100%);
    opacity: 0;
}

@media screen and (max-width: 650px) {
    .cookie-notice {
        & .center-constraint {
            flex-direction: column;
            align-items: stretch;
            text-align: center;
        }

        & .base-button {
            margin: 1rem 0 0;
        }
    }
}

@media print {
    .cookie-notice {
        display: none;
    }
}
</style>
