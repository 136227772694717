export const useLayoutStore = defineStore('layout', () => {
    const route = useRoute();
    const appConfig = useAppConfig();

    // For hiding the footer form on specific routes
    const showFooterForm = computed(() => {
        return !hasHiddenRoutes(
            appConfig.layoutOptions.hiddenFooterFormPages,
            route
        );
    });

    // For hiding the 'Back To Top' button on specific routes
    const showBackToTop = computed(() => {
        return !hasHiddenRoutes(
            appConfig.layoutOptions.hiddenBackToTopPages,
            route
        );
    });

    return { showFooterForm, showBackToTop };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useLayoutStore, import.meta.hot));
}
