<script setup lang="ts">
const props = defineProps<{
    imageGif?: ChapmanImage;
    imageWebp?: ChapmanImage;
    imageSvg?: ChapmanImage;
    imagesWebp?: Record<string, ChapmanImage>;
    imagesSvg?: Record<string, ChapmanImage>;
}>();
</script>

<template>
    <header>
        <HeaderDefaultAddressBar />

        <HeaderDefaultNavBar
            :image-gif="props.imageGif"
            :image-webp="props.imageWebp"
            :image-svg="props.imageSvg"
            :images-webp="props.imagesWebp"
            :images-svg="props.imagesSvg"
        />
    </header>
</template>

<style lang="postcss" scoped>
header {
    position: relative;
    z-index: 20;
}

@media print {
    * {
        display: none;
    }
}
</style>
