<script setup lang="ts">
import LocationOn from '@base/assets/icons/location_on.svg?raw';
import Phone from '@base/assets/icons/phone.svg?raw';

const dealersStore = useDealersStore();
const navigationStore = useNavigationStore();
const { $cagGoogle } = useNuxtApp();
const componentName = getCurrentInstance()?.type.__name ?? '';

const handlePhoneClick = (phoneNumber: string) => {
    navigationStore.RESET_ALL_NAV();

    $cagGoogle.pushPhoneToDataLayer(phoneNumber, componentName);
};
</script>

<template>
    <div class="text-links">
        <div class="address">
            <NuxtLink
                :to="{ name: 'contact' }"
                aria-label="address (link to contact page)"
                @click="navigationStore.RESET_ALL_NAV()"
            >
                <BaseIcon :icon-html="LocationOn" class="base-icon" />
                <span>
                    {{ dealersStore.currentDealer.address }}
                    {{ dealersStore.currentDealer.city }},
                    {{ dealersStore.currentDealer.state }}
                    {{ dealersStore.currentDealer.zip }}
                </span>
            </NuxtLink>
        </div>

        <div class="phone">
            <a
                :href="`tel:${dealersStore.currentDealer.phone}`"
                :aria-label="`Dial ${dealersStore.currentDealer.phone}`"
                class="tel"
                @click.passive="
                    handlePhoneClick(dealersStore.currentDealer.phone)
                "
            >
                <BaseIcon :icon-html="Phone" class="base-icon" />
                <span>{{ dealersStore.currentDealer.phone }}</span>
            </a>
        </div>
    </div>
</template>

<style lang="postcss" scoped>
.text-links {
    margin-right: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    & a {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-header-text);

        &:hover,
        &:focus {
            color: var(--color-header-address-bar-hover);
        }

        & .base-icon {
            --base-icon-size: 18px;
            --base-icon-stroke: none;

            margin-right: 5px;
        }
    }
}
</style>
