<script setup lang="ts">
import ChevronUp from '@/assets/icons/chevron_up.svg?raw';

// Get the current y position of the window
const { y } = useWindowScroll();

// For showing the div element
const isScrolled = ref(false);

// Debounce the logic that checks if the user has scroll, for performance reasons
const debouncedOnScroll = useDebounceFn(() => {
    // Show the div when user has scrolled beyond 200 pixels in Y direction
    if (y.value > 200) isScrolled.value = true;
    else isScrolled.value = false;
}, 250);

// Mount and unmount the debounced function
onMounted(() => {
    window.addEventListener('scroll', debouncedOnScroll);
});

onUnmounted(() => {
    window.removeEventListener('scroll', debouncedOnScroll);
});

// For scrolling user to top of page
const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};
</script>

<template>
    <button
        :tabindex="isScrolled ? 0 : -1"
        type="button"
        class="back-to-top"
        :class="{ show: isScrolled }"
        @click="scrollToTop"
    >
        <span>Top</span>

        <BaseIcon :icon-html="ChevronUp" aria-hidden="true" class="arrow-up" />
    </button>
</template>

<style lang="postcss" scoped>
.back-to-top {
    position: fixed;
    bottom: 20px;
    left: 20px;
    transition: var(--transition-base);
    transform: translateY(75px);
    padding: 0 15px;
    padding-right: 10px;
    line-height: 44px;
    cursor: pointer;
    background-color: var(--color-accent);
    color: var(--color-text-on-accent);
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    z-index: 400;
    user-select: none;
    box-shadow: 2px 2px 7px rgba(0 0 0 / 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-family: var(--font-family);
    font-size: 1rem;

    & span {
        margin-right: 5px;
    }

    &.show {
        transform: translateY(0);
    }

    & .arrow-up {
        --base-icon-size: 1.5rem;
        --base-icon-stroke-width: 3;
    }

    &:hover,
    &:focus {
        & .arrow-up {
            animation-name: bounce;
            animation-duration: 350ms;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-timing-function: ease-in;
        }
    }

    &:focus {
        border: 2px solid var(--color-font-light);
    }

    &.theme--bmw {
        text-transform: capitalize;
    }
}

@keyframes bounce {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-3px);
    }
}

@media print {
    .back-to-top {
        display: none;
    }
}
</style>
