<script setup lang="ts">
const appConfig = useAppConfig();
const navigationStore = useNavigationStore();
</script>

<template>
    <div v-if="appConfig.headingOptions.splitName[0]" class="header-name">
        <NuxtLink
            :to="{ name: 'index' }"
            class="nuxt-link"
            @click="navigationStore.RESET_ALL_NAV()"
        >
            <span
                v-for="(word, index) in appConfig.headingOptions.splitName"
                :key="index"
                class="word"
                :class="{
                    first: index === 0,
                    uppercase: appConfig.headingOptions.uppercase,
                }"
            >
                {{ word }}
            </span>
        </NuxtLink>
    </div>
</template>

<style lang="postcss" scoped>
.header-name {
    font-size: 24px;
    font-family: var(--font-family);
    flex: auto;
    display: flex;

    & .nuxt-link {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 0.125rem;
        flex-wrap: wrap;
        padding-left: 20px;
        color: var(--color-header-address-name-text);

        &:hover,
        &:focus {
            color: var(--color-header-address-bar-hover);
        }
    }

    & .word {
        margin-right: 0.25rem;

        &.uppercase {
            text-transform: uppercase;
        }
    }

    & .first {
        font-weight: var(--font-weight-bold);
        text-transform: uppercase;
    }
}

@media screen and (max-width: 940px) {
    & .header-name {
        font-size: 1.25rem;

        & .nuxt-link {
            line-height: 0.9;
            padding: 0.25rem 0 0.25rem 20px;
        }

        & .first {
            width: 100%;
        }
    }
}

@media screen and (max-width: 560px) {
    & .header-name {
        font-size: 16px;

        & .first {
            font-size: 20px;
        }
    }
}

@media screen and (max-width: 440px) {
    & .header-name {
        display: none;
    }
}
</style>
