<script setup lang="ts">
const props = defineProps<{
    imagesWebp?: Record<string, ChapmanImage>;
    imagesSvg?: Record<string, ChapmanImage>;
}>();

const dealersStore = useDealersStore();
</script>

<template>
    <div class="logos">
        <template v-if="props.imagesWebp">
            <div
                v-for="(_, index) in props.imagesWebp"
                :key="index"
                class="logo-wrap"
                :class="{
                    'small-logo': props.imagesWebp[index].isSmall,
                }"
            >
                <img
                    :alt="`${dealersStore.siteName} sells ${index}`"
                    :src="props.imagesWebp[index].src"
                    :width="props.imagesWebp[index].width"
                    :height="props.imagesWebp[index].height"
                    class="logo"
                />
            </div>
        </template>

        <template v-if="props.imagesSvg">
            <div
                v-for="(_, index) in props.imagesSvg"
                :key="index"
                class="logo-wrap"
                :class="{
                    'small-svg-logo': props.imagesSvg[index].isSmall,
                }"
            >
                <img
                    :alt="`${dealersStore.siteName} sells ${index}`"
                    :src="props.imagesSvg[index].src"
                    :width="props.imagesSvg[index].width"
                    :height="props.imagesSvg[index].height"
                    class="logo"
                />
            </div>
        </template>
    </div>
</template>

<style lang="postcss" scoped>
.logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.logo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: var(--size-logo-max-width);
    margin: 10px;

    &.small-logo {
        max-width: 80px;
    }
}

.logo {
    display: block;
    width: 100%;
    height: auto;

    & img {
        display: block;
        width: 100%;
    }
}

@media screen and (max-width: 1060px) {
    & .logos {
        height: var(--size-mobile-nav-bar-height);

        & .logo-wrap {
            max-width: var(--size-mobile-logo-max-width);
            margin: 0;

            &.small-logo {
                max-width: 55px;
            }
        }
    }
}

@media screen and (max-width: 440px) {
    & .logos {
        display: none;

        & .name {
            font-size: 16px;
        }
    }
}
</style>
