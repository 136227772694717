<script setup lang="ts">
import type { RouteLocationRaw } from '#vue-router';

import ChevronLeft from '@base/assets/icons/chevron_left.svg?raw';

const props = defineProps<{
    chapmanLink: ChapmanLink;
    open: boolean;
}>();
const emit = defineEmits<{ closeSubMenu: []; close: [] }>();

const appConfig = useAppConfig();
const specialsStore = useSpecialsStore();
const inventoryStore = useInventoryStore();

// For making type agnostic link
const isAgnosticLink =
    // If not hiding Make & Model links
    !appConfig.navOptions.hideMakeModelLinks &&
    // Narrows link type to non-string route
    typeof props.chapmanLink.link === 'object' &&
    // Narrows link type to typed named route
    'name' in props.chapmanLink.link &&
    // Check if link is SRP link
    !!props.chapmanLink.link.name &&
    props.chapmanLink.link.name.includes('search') &&
    // Check if SRP link is type agnostic
    !props.chapmanLink.link.params &&
    // Ignore links to SRP if there are query values (electric on BMW)
    !Object.keys(props.chapmanLink.link.query || {}).length;

// For making New SRP link
const isNewSrpLink =
    // If not hiding Make & Model links
    !appConfig.navOptions.hideMakeModelLinks &&
    // Narrows link type to non-string route
    typeof props.chapmanLink.link === 'object' &&
    // Narrows link type to typed named route
    'name' in props.chapmanLink.link &&
    // Check if link is SRP link
    !!props.chapmanLink.link.name &&
    props.chapmanLink.link.name.includes('search') &&
    // Check if SRP link is going to new
    props.chapmanLink.link.params &&
    'type' in props.chapmanLink.link.params &&
    props.chapmanLink.link.params.type === 'new' &&
    // Ignore links to `new` SRP if there are query values (electric on BMW)
    !Object.keys(props.chapmanLink.link.query || {}).length;

const getNewLink = (make: string, model: string): RouteLocationRaw => {
    if (appConfig.hub.isChoice) {
        return `/search/new/cars/${urlEncode(make)}/${urlEncode(model)}`;
    } else if (appConfig.hub.location) {
        return `/search/new/cars/${appConfig.hub.location}/${urlEncode(
            make
        )}/${urlEncode(model)}`;
    } else {
        return `/search/new/${urlEncode(make)}/${urlEncode(model)}`;
    }
};

const isSpecialsLink =
    // Narrows link type to non-string route
    typeof props.chapmanLink.link === 'object' &&
    // Narrows link type to typed named route
    'name' in props.chapmanLink.link &&
    // Check if link is Specials link
    !!props.chapmanLink.link.name &&
    props.chapmanLink.link.name.includes('specials');

const getSpecialsLink = (make: string, model: string): RouteLocationRaw => {
    return `/specials/new/${urlEncode(make)}/${urlEncode(model)}/${urlEncode(
        appConfig.seoOptions.region
    )}`;
};

const getMakeModelTitle = (makeModel: { make: string; model: string }) => {
    return getCleanVehicleTitleArray({
        make: appConfig.navOptions.hideMake ? undefined : makeModel.make,
        model: makeModel.model,
    }).join(' ');
};

// Show the available tab duplicate link
const showTabDuplicateLink = (() => {
    return Object.keys(props.chapmanLink.link).length !== 0;
})();
</script>

<template>
    <div :class="{ open: open }" class="nav-sub-menu">
        <CenterContent padding="20px 2rem" class="center-content">
            <div class="mobile-controls">
                <button
                    aria-label="close navigation"
                    type="button"
                    class="menu-back"
                    @click="emit('closeSubMenu')"
                >
                    <BaseIcon
                        :icon-html="ChevronLeft"
                        aria-hidden="true"
                        class="chevron-icon"
                    />
                </button>

                <span class="menu-title">
                    {{ props.chapmanLink.display }}
                </span>
            </div>

            <NuxtLink
                v-if="showTabDuplicateLink"
                :to="props.chapmanLink.link"
                class="tab-duplicate-link"
                @click="emit('close')"
            >
                {{ props.chapmanLink.display }}
            </NuxtLink>

            <template v-if="isAgnosticLink || isNewSrpLink">
                <NuxtLink
                    v-for="makeModel in inventoryStore.uniqueNewCars"
                    :key="`${makeModel.make}-${makeModel.model}`"
                    :to="getNewLink(makeModel.make, makeModel.model)"
                    @click="emit('close')"
                >
                    {{ getMakeModelTitle(makeModel) }}
                    <ClientOnly>
                        {{ inventoryStore.GET_COUNT(makeModel) }}
                    </ClientOnly>
                </NuxtLink>
            </template>

            <template v-if="isSpecialsLink">
                <NuxtLink
                    v-for="makeModel in specialsStore.uniqueMakeModels"
                    :key="`${makeModel.make}-${makeModel.model}`"
                    :to="getSpecialsLink(makeModel.make, makeModel.model)"
                    @click="emit('close')"
                >
                    {{ getMakeModelTitle(makeModel) }} Offers
                </NuxtLink>
            </template>

            <template
                v-for="(child, index) in props.chapmanLink.children"
                :key="`${child}-${index}`"
            >
                <NuxtLink
                    :target="isExternalLink(child) ? '_blank' : undefined"
                    :rel="isExternalLink(child) ? 'noopener' : undefined"
                    :to="child.link"
                    @click="emit('close')"
                >
                    {{ child.display }}
                </NuxtLink>
            </template>
        </CenterContent>
    </div>
</template>

<style lang="postcss" scoped>
.nav-sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    transform: translateX(-100%);
    opacity: 0;
    transition: var(--transition-base);
    background-color: #fff;
    box-shadow: 0 3px 5px rgba(0 0 0 / 25%);
    z-index: 14; /* 1 less than AddressMychapman */

    &.open {
        opacity: 1;
        transform: translateX(0);
    }

    & .center-content {
        display: flex;
        flex-wrap: wrap;
    }

    & .mobile-controls {
        display: none;
        width: 100%;
        padding: 0 10px 20px;
        align-items: center;

        & .menu-back {
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            cursor: pointer;
            border-radius: 50%;
            transition: var(--transition-base);
            background-color: var(--color-light-gray);
            border: none;

            &:hover,
            &:focus {
                background-color: var(--color-dark-gray);
                color: #fff;
            }
        }

        & .menu-title {
            font-size: 24px;
        }
    }

    & a {
        padding: 15px;
        background-color: var(--color-light-gray);
        flex: auto;
        text-align: center;
        margin: 10px;

        &:hover,
        &:focus {
            background-color: var(--color-accent);
            color: var(--color-text-on-accent);
        }
    }
}

.chevron-icon {
    --base-icon-size: 22px;
    --base-icon-stroke-width: 3;
}
</style>
