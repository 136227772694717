<script setup lang="ts">
const props = defineProps<{
    imageGif?: ChapmanImage;
    imageWebp?: ChapmanImage;
    imageSvg?: ChapmanImage;
}>();

const appConfig = useAppConfig();
const dealersStore = useDealersStore();
const navigationStore = useNavigationStore();

const logoAltText = (() => {
    let makes = '';

    if (dealersStore.currentDealer.makes.length === 1) {
        makes = dealersStore.currentDealer.makes[0];
    } else {
        makes = dealersStore.currentDealer.makes.slice(0, -1).join(', ');

        if (dealersStore.currentDealer.makes.length > 2) {
            makes += `, and ${dealersStore.currentDealer.makes.slice(-1)}`;
        } else if (dealersStore.currentDealer.makes.length > 1) {
            makes += ` and ${dealersStore.currentDealer.makes.slice(-1)}`;
        }
    }

    return `${dealersStore.siteName} sells ${makes}`;
})();
</script>

<template>
    <div class="dealer-name-and-logo">
        <div class="logo">
            <img
                v-if="props.imageGif"
                :alt="logoAltText"
                :src="props.imageGif.src"
                :width="props.imageGif.width"
                :height="props.imageGif.height"
            />

            <img
                v-else-if="props.imageWebp"
                :alt="logoAltText"
                :src="props.imageWebp.src"
                :width="props.imageWebp.width"
                :height="props.imageWebp.height"
            />

            <img
                v-else
                :alt="logoAltText"
                :src="props.imageSvg?.src"
                :width="props.imageSvg?.width"
                :height="props.imageSvg?.height"
                :class="{ 'small-svg-logo': props.imageSvg?.isSmall }"
            />
        </div>

        <NuxtLink
            :to="{ name: 'index' }"
            class="dealer-name-link"
            @click="navigationStore.CLOSE_NAV"
        >
            <span
                v-for="group in appConfig.headingOptions.splitName"
                :key="group"
                class="dealer-name-group"
            >
                {{ group }}
            </span>
        </NuxtLink>
    </div>
</template>

<style lang="postcss" scoped>
.dealer-name-and-logo {
    display: none;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: var(--size-mobile-nav-bar-height);

    & .logo {
        display: flex;
        max-width: var(--size-mobile-logo-max-width);
        width: 100%;

        & img {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    & .dealer-name-link {
        flex-shrink: 0;
        color: var(--color-font-light);
        line-height: 1.1;
        display: flex;
        flex-direction: column;

        &:hover,
        &:focus {
            color: var(--color-header-address-bar-hover);
        }
    }

    & .dealer-name-group {
        &:first-child {
            font-weight: var(--font-weight-bold);
        }
    }

    & picture {
        width: 100%;
    }
}

@media screen and (max-width: 440px) {
    .dealer-name-and-logo {
        display: flex;
    }
}
</style>
