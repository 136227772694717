<script setup lang="ts">
import { headerLinks, footerLinks, footerMembers } from '@/navigation';

import Assets from '@/public/marketing-assets/marketing-assets.json';
import LogosChryslerWebp from '@retail-base/assets/images/logos/Chrysler.webp';
import LogosDodgeWebp from '@retail-base/assets/images/logos/Dodge.webp';
import LogosJeepWebp from '@retail-base/assets/images/logos/Jeep.webp';
import LogosRamWebp from '@retail-base/assets/images/logos/Ram.webp';
import LogosCYDBrandsGif from '@retail-base/assets/images/logos/CYD-Brands.gif';

const route = useRoute();
const seoStore = useSeoStore();
const appConfig = useAppConfig();
const chatStore = useChatStore();
const layoutStore = useLayoutStore();
const dealersStore = useDealersStore();
const specialsStore = useSpecialsStore();
const inventoryStore = useInventoryStore();
const navigationStore = useNavigationStore();

/**
 * This is where the initial data for all stores is run.
 * Doing this here because nuxtServerInit is no longer a thing.
 */
appConfig.infoBanners = filterAssets(Assets, 'banner');
appConfig.searchOptions.searchCards = filterAssets(Assets, 'search-card');
await Promise.all([
    dealersStore.GET_DEALER(appConfig.arkonas.site),
    seoStore.GET_TAGS(),
    inventoryStore.GET_UNIQUE_NEW_CARS(),
    specialsStore.GET_BANNER(),
]);
navigationStore.header.chapmanLinks = headerLinks;
navigationStore.footer.chapmanLinks = footerLinks;
navigationStore.footer.memberLinks = footerMembers;

// For getting model counts in Nav and Footer
if (!inventoryStore.all.length && import.meta.client) {
    inventoryStore.GET_ALL();
}

// For the site header
const gifImage: ChapmanImage = {
    src: LogosCYDBrandsGif,
    width: 250,
    height: 125,
};
const imagesWebp: Record<string, ChapmanImage> = {
    Dodge: { src: LogosDodgeWebp, width: 280, height: 40 },
    Chrysler: { src: LogosChryslerWebp, width: 400, height: 60 },
    Jeep: { src: LogosJeepWebp, width: 190, height: 76, isSmall: true },
    Ram: { src: LogosRamWebp, width: 230, height: 69 },
};

// For head meta
const seoData = {
    pageId: 11,
    dealer: dealersStore.siteName,
};
const metaTitle = createTag(
    `${dealersStore.siteName} | New & used car dealer in ${dealersStore.currentDealer.city} ${dealersStore.currentDealer.state}`,
    'title',
    seoData
);
const metaDescription = createTag(
    `Browse vehicle specials, shop for new and used Dodge Chrysler Jeep Ram cars, trucks, and SUVs, schedule service, and order genuine Dodge Chrysler Jeep Ram parts online through ${dealersStore.siteName} in ${dealersStore.currentDealer.state}.`,
    'description',
    seoData
);
useSeoMeta({
    title: metaTitle,
    description: metaDescription,

    // Facebook
    ogType: 'website',
    ogTitle: metaTitle,
    ogDescription: metaDescription,
    ogImage: `https://assets.chapmanchoice.com/img/dealers/${appConfig.arkonas.site}.webp`,
    ogUrl: () => `https://${dealersStore.siteUrl}${route.path}`,

    // Twitter
    twitterCard: 'summary_large_image',
    twitterTitle: metaTitle,
    twitterDescription: metaDescription,
    twitterImage: `https://assets.chapmanchoice.com/img/dealers/${appConfig.arkonas.site}.webp`,
});
useHead({
    link: () => [
        {
            rel: 'canonical',
            href: `https://${dealersStore.siteUrl}${route.path}`,
        },
    ],
    script: [generateAutomotiveSchema({})],
});
</script>

<template>
    <div id="app" :class="{ 'nav-open': navigationStore.navOpen }">
        <HeaderDefaultSiteHeader
            :image-gif="gifImage"
            :images-webp="imagesWebp"
        />
        <SpecialBanner />
        <div>
            <slot></slot>
        </div>
        <FormsFooterForm
            v-if="layoutStore.showFooterForm"
            :key="route.fullPath"
        />
        <FooterDealer />
        <BackToTop v-if="layoutStore.showBackToTop" />
        <CookieNotice />
        <ClientOnly>
            <LazyChatCagChat v-if="!chatStore.isBlocked" />
        </ClientOnly>
    </div>
</template>
