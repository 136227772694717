<script setup lang="ts">
const props = defineProps<{
    imageGif?: ChapmanImage;
    imageWebp?: ChapmanImage;
    imageSvg?: ChapmanImage;
    imagesWebp?: Record<string, ChapmanImage>;
    imagesSvg?: Record<string, ChapmanImage>;
}>();

const navigationStore = useNavigationStore();

// Sets the current open navigation menu name
// Repeated name will clear the current open menu
const setOpenMenu = (name: string) => {
    if (navigationStore.header.openMenu === name) {
        navigationStore.CLEAR_SUB_MENU();
    } else {
        navigationStore.SET_SUB_MENU(name);
    }
};

// Resize the browser to sets navigationStore.header.open
const resetNavMenu = useDebounceFn(function () {
    if (window.innerWidth > 1060) {
        if (navigationStore.header.open) {
            navigationStore.CLOSE_NAV();
        }
    } else {
        if (!navigationStore.header.open && navigationStore.header.sub) {
            navigationStore.TOGGLE_NAV();
        }
    }
}, 10);

onMounted(() => {
    window.addEventListener('resize', resetNavMenu);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', resetNavMenu);
});
</script>

<template>
    <div class="nav-bar">
        <CenterContent padding="0 1rem">
            <HeaderDefaultNavLogos
                :images-webp="props.imagesWebp"
                :images-svg="props.imagesSvg"
            />

            <nav
                :class="{
                    open: navigationStore.header.open,
                }"
            >
                <HeaderDefaultNavTopLevel
                    v-for="link in navigationStore.header.chapmanLinks"
                    :key="link.display"
                    :chapman-link="link"
                    @click="setOpenMenu"
                />
            </nav>

            <HeaderDefaultNavLogoWithName
                :image-gif="props.imageGif"
                :image-webp="props.imageWebp"
                :image-svg="props.imageSvg"
            />
        </CenterContent>
    </div>
</template>

<style lang="postcss" scoped>
.nav-bar {
    background-color: var(--color-header-nav-bar-bg);
}

nav {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 1060px) {
    .nav-bar {
        height: var(--size-mobile-nav-bar-height);

        & nav {
            position: fixed;
            inset: var(--size-mobile-nav-height) 0 0 0;
            z-index: 999;
            transition: var(--transition-base);
            transform: translateX(-100%);
            opacity: 0;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            padding: 20px;
            background-color: #fff;
            overflow-y: auto;

            &.open {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }
}
</style>
