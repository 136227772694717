export const useChatStore = defineStore('chat', () => {
    const chatBlockedKey = 'cag_chat_permission';
    const chatBlockedValue = 'disallow';
    const chatPermission = ref('');

    const isBlocked = computed(() => chatPermission.value === chatBlockedValue);

    function checkIfChatDisallowed() {
        try {
            // Since localStorage is not type-safe, explicitly check the value
            const blockedValue = localStorage[chatBlockedKey];
            if (blockedValue === chatBlockedValue) {
                chatPermission.value = blockedValue;
            }
        } catch (err) {
            handleLocalStorageError(err);
        }
    }

    /**
     * It is generally not a good idea to use lifecycle hooks in pinia because
     * pinia can run outside of component context. So do this check to ensure
     * that onMounted only runs when inside a component.
     */
    if (getCurrentInstance()) {
        onMounted(() => {
            checkIfChatDisallowed();
        });
    }

    return {
        chatBlockedKey,
        chatBlockedValue,
        chatPermission,
        isBlocked,
        checkIfChatDisallowed,
    };
});
