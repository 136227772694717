<script setup lang="ts">
import Close from '@/assets/icons/close.svg?raw';
import Menu from '@/assets/icons/menu.svg?raw';

const navigationStore = useNavigationStore();

const toggleMenu = () => {
    navigationStore.TOGGLE_NAV();
    if (navigationStore.header.sub) {
        navigationStore.TOGGLE_SUB_NAV();
    }

    if (navigationStore.header.openMenu) {
        navigationStore.CLEAR_SUB_MENU();
    }
};
</script>

<template>
    <button
        :aria-checked="navigationStore.header.open ? 'true' : 'false'"
        aria-label="toggle menu"
        role="switch"
        type="button"
        class="hamburger"
        :class="{ open: navigationStore.header.open }"
        @click="toggleMenu()"
    >
        <transition name="spin-fade" mode="out-in">
            <BaseIcon
                v-if="navigationStore.header.open"
                :icon-html="Close"
                class="base-icon"
            />

            <BaseIcon v-else :icon-html="Menu" class="base-icon" />
        </transition>
    </button>
</template>

<style lang="postcss" scoped>
.hamburger {
    display: none;
    border: none;
    background: none;
    justify-content: center;
    align-items: center;
    width: 48px;
    cursor: pointer;
    transition: var(--transition-base);
    color: var(--color-header-text);

    &:hover,
    &:focus {
        background-color: var(--color-accent);
        color: var(--color-text-on-accent);
    }

    & .base-icon {
        --base-icon-size: 1.5rem;
        --base-icon-stroke: none;
    }
}

.spin-fade-enter-active,
.spin-fade-leave-active {
    transition: var(--transition-base);
}

.spin-fade-enter-from {
    opacity: 0;
    transform: rotate(-180deg);
}

.spin-fade-leave-to {
    opacity: 0;
    transform: rotate(180deg);
}
</style>
