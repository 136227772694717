<script setup lang="ts">
import ChevronRight from '@base/assets/icons/chevron_right.svg?raw';

const { $cagGoogle } = useNuxtApp();
const route = useRoute();
const appConfig = useAppConfig();
const chapmanConnectStore = useChapmanConnectStore();
const formApi = new FormApi(appConfig.arkonas.site);

// For form validation
const initialValues = {
    honeypot: '',
    name: '',
    email: '',
    phone: '',
    message: '',
};
const validationSchema = {
    name(value: string) {
        return validateString(value, {
            required: true,
            fieldName: 'Name',
        });
    },
    email(value: string) {
        return validateEmail(value, {
            required: true,
            fieldName: 'Email',
        });
    },
    phone(value: string) {
        return validatePhone(value, {
            fieldName: 'Phone',
        });
    },
    message(value: string) {
        return validateString(value, {
            required: true,
            fieldName: 'Message',
        });
    },
};
const footerForm = useForm({ initialValues, validationSchema });
const { values, handleSubmit } = footerForm;

// For the submit button
const submitted = ref(false);
const isSubmitting = ref(false);
const submissionError = ref(false);
const onSubmit = handleSubmit(async () => {
    try {
        // If the form is already sending, this prevents double clicks
        if (isSubmitting.value) return;

        // Do not submit if honeypot has value
        if (values.honeypot.length) return;

        isSubmitting.value = true;

        const payload: FormContact = {
            arkona: appConfig.arkonas.sales || appConfig.arkonas.site,
            source: 'DW-Footer',
            honeypot: values.honeypot,
            name: values.name,
            email: values.email,
            phone: values.phone,
            message: values.message,
        };

        const isVdp =
            route.name === 'detail-type-year-make-model-stock-uniqueArkona';

        if (isVdp) {
            // VDP specific values
            payload.arkona = getLeadArkona(
                route.params.uniqueArkona || '',
                route.params.type,
                appConfig.arkonas
            );
            payload.source = 'DW-Vehicle-Interest';
            payload.newOrUsed = route.params.type.toString();

            const maybeYear = Number(route.params.year);
            if (!isNaN(maybeYear)) {
                payload.year = maybeYear;
            }

            payload.make = urlDecode(route.params.make.toString());
            payload.model = urlDecode(route.params.model.toString());
            payload.stockNumber = route.params.stock.toString();
            payload.vin = chapmanConnectStore.vehicleVin;
            payload.mileage = chapmanConnectStore.mileage;
            payload.priceShown = chapmanConnectStore.discountedPriceValue;
        }

        // For certification
        if (appConfig.arkonas.site === 'cao') {
            if (isVdp) {
                payload.manufacturerProgramCode =
                    window.innerWidth > 650 ? '95052' : '95053';
                payload.leadSubProgram = 'DRS Request A Quote Form';
            } else {
                payload.manufacturerProgramCode = '95027';
                payload.leadSubProgram = 'Footer Contact';
            }

            payload.sendForManufacturerCert = import.meta.env.PROD
                ? 'production'
                : 'staging';
        } else if (appConfig.arkonas.site === 'can') {
            if (isVdp) {
                payload.manufacturerProgramCode =
                    window.innerWidth > 650 ? '90816' : '90817';
                payload.leadSubProgram = 'DRS Request A Quote Form';
            } else {
                payload.manufacturerProgramCode = '90104';
                payload.leadSubProgram = 'Footer Contact';
            }

            payload.sendForManufacturerCert = import.meta.env.PROD
                ? 'production'
                : 'staging';
        }

        await formApi.saveContactForm(payload);

        submissionError.value = false;
        submitted.value = true;
        isSubmitting.value = false;

        $cagGoogle.pushToDataLayer({ action: 'submit', source: 'footer form' });

        if (appConfig.arkonas.site === 'can') {
            $cagGoogle.pushToHondaDataLayer({
                event: 'autodata.honda.form-submitted',
                Dealerid: appConfig.keys.honda?.dealerId,
                hform:
                    route.name ===
                    'detail-type-year-make-model-stock-uniqueArkona'
                        ? 'DWP-Sales-Form'
                        : 'DWP-GA-Contact',
                formclass:
                    route.name ===
                    'detail-type-year-make-model-stock-uniqueArkona'
                        ? 'Sales'
                        : 'GeneralAdmin',
            });
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        submitted.value = false;
        submissionError.value = true;
        isSubmitting.value = false;
    }
});
</script>

<template>
    <div class="footer-form-wrap">
        <CenterContent padding="35px 2.5rem 40px">
            <div
                class="footer-form-header-wrap"
                :class="{
                    'center-header': appConfig.footerFormOptions.centerText,
                }"
            >
                <HeadingText
                    :center-text="appConfig.footerFormOptions.centerText"
                    :flavor="appConfig.footerFormOptions.flavorText"
                    class="heading-text"
                >
                    Contact Us
                </HeadingText>
            </div>

            <form
                v-show="!isSubmitting && !submissionError && !submitted"
                class="footer-form"
                @submit="onSubmit"
            >
                <FormsInputText name="honeypot" type="hidden" />

                <FormsInputText
                    :form="footerForm"
                    label="Name"
                    name="name"
                    type="text"
                    required
                    class="footer-field"
                />

                <FormsInputText
                    :form="footerForm"
                    label="Email"
                    name="email"
                    type="email"
                    required
                    class="footer-field"
                />

                <FormsInputCleave
                    :form="footerForm"
                    label="Phone"
                    name="phone"
                    type="phone"
                    class="footer-field"
                />

                <FormsInputTextarea
                    :form="footerForm"
                    label="Message"
                    name="message"
                    required
                    class="footer-field input-textarea"
                />

                <div class="submit-wrap">
                    <BaseButton class="base-button">
                        <button type="submit">
                            <span><b>Submit</b></span>

                            <BaseIcon
                                :icon-html="ChevronRight"
                                aria-hidden="true"
                                class="chevron-icon"
                            />
                        </button>
                    </BaseButton>
                </div>
            </form>

            <LoadingSpinner v-if="isSubmitting" class="loading-spinner" />

            <div v-if="!isSubmitting && submitted">
                <span>
                    Thank you for contacting us. Your form has been received and
                    someone will be in touch soon.
                </span>
            </div>

            <div v-if="!isSubmitting && submissionError">
                <span>
                    Sorry, but there was a problem submitting your form. Please
                    refresh the page and try again or give us a call.
                </span>
            </div>
        </CenterContent>
    </div>
</template>

<style lang="postcss" scoped>
.footer-form-wrap {
    --color-error: var(--color-footer-form-error);

    background-color: var(--color-footer-form-bg);
    color: var(--color-footer-form-text);

    & .heading-text {
        & :deep(.main) {
            color: var(--color-footer-form-header);
        }

        & :deep(.flavor) {
            color: var(--color-footer-form-flavor);
        }
    }
}

.footer-form-header-wrap {
    display: flex;

    &.center-header {
        justify-content: center;
    }

    & .heading-text {
        width: unset;
    }
}

.footer-form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    & .footer-field {
        /* Set the error wrap height the same as the form label to center align the form elements in desktop view */
        --field-wrap-label-height: 30px;
        --field-wrap-error-height: 30px;

        width: 19%;
        position: relative;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;

        &.field-wrap {
            /* Because of :deep, all styles from FieldWrap need to be duplicated here */
            & :deep(.content) {
                & .required {
                    color: var(--color-footer-form-required);

                    &.valid {
                        color: var(--color-field-wrap-valid);
                    }

                    &.touched:not(.valid) {
                        color: var(--color-footer-form-error);
                    }
                }
            }
        }
    }

    & .input-textarea {
        &:deep(textarea) {
            height: 44px;
        }
    }

    & .base-button {
        height: 44px;
    }
}

.loading-spinner {
    align-self: center;
    padding: 20px 0;
}

& .chevron-icon {
    --base-icon-size: 26px;
    --base-icon-stroke-width: 3;
}

@media screen and (max-width: 1080px) {
    .footer-form {
        row-gap: 10px;

        & .footer-field {
            width: 24%;
        }

        & .submit-wrap {
            width: 100%;
            margin: 0 auto;
            max-width: 250px;
        }

        & .base-button {
            height: 56px;
            margin-top: 1rem;
        }
    }
}

@media screen and (max-width: 850px) {
    .footer-form {
        & .footer-field {
            width: 49%;
        }

        & .submit-wrap {
            width: 100%;
        }
    }
}

@media screen and (max-width: 580px) {
    .footer-form {
        & .footer-field {
            width: 100%;
        }

        & .base-button {
            margin-top: initial;
        }
    }
}

@media print {
    * {
        display: none;
    }
}
</style>
