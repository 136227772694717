<script setup lang="ts">
import ArrowDropDown from '@base/assets/icons/arrow_drop_down.svg?raw';
import ChevronRight from '@base/assets/icons/chevron_right.svg?raw';

const props = defineProps<{
    chapmanLink: ChapmanLink;
    showArrows?: boolean;
}>();

const emit = defineEmits<{ toggleSubNav: []; close: [] }>();
</script>

<template>
    <button
        v-if="props.chapmanLink.children"
        class="nav-link"
        type="button"
        @click="emit('toggleSubNav')"
    >
        {{ props.chapmanLink.display }}
        <div v-if="props.showArrows" class="arrow-icon-wrap">
            <BaseIcon
                :icon-html="ArrowDropDown"
                aria-hidden="true"
                class="arrow-icon desktop-only"
            />
            <BaseIcon
                :icon-html="ChevronRight"
                aria-hidden="true"
                class="chevron-icon mobile-only"
            />
        </div>
    </button>

    <NuxtLink
        v-else
        :to="props.chapmanLink.link"
        :target="isExternalLink(props.chapmanLink) ? '_blank' : undefined"
        :rel="isExternalLink(props.chapmanLink) ? 'noopener' : undefined"
        class="nav-link"
        @click="emit('close')"
    >
        {{ props.chapmanLink.display }}
    </NuxtLink>
</template>

<style lang="postcss" scoped>
.nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 44px;
    padding: 0 15px;
    border-bottom: 5px solid transparent;
    background-color: transparent;
    border-color: transparent;
    font-size: 1rem;
    font-family: var(--font-family);
    color: var(--color-font-light);

    &button {
        padding-right: 0;
        border-width: 0 0 5px;
    }

    & .arrow-icon-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        transition: var(--transition-base);

        & .arrow-icon {
            --base-icon-size: 18px;
        }
    }

    &:hover,
    &:focus {
        border-bottom: 5px solid var(--color-accent);
    }
}

.chevron-icon {
    --base-icon-size: 22px;
    --base-icon-stroke-width: 3;
}

.base-icon {
    &.mobile-only {
        display: none;
    }

    &.desktop-only {
        display: block;
    }
}

@media screen and (max-width: 940px) {
    .nav-link {
        padding: 15px;
        background-color: var(--color-light-gray);
        flex: auto;
        text-align: center;
        margin: 10px;
        cursor: pointer;
        transition: var(--transition-base);
        color: var(--color-font-dark);
        border: none;
        height: auto;

        &:hover,
        &:focus {
            background-color: var(--color-accent);
            color: var(--color-text-on-accent);
            border-bottom: none;
        }
    }

    .base-icon {
        &.mobile-only {
            display: block;
        }

        &.desktop-only {
            display: none;
        }
    }
}
</style>
