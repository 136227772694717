<template>
    <div class="address-bar">
        <CenterContent class="center-content">
            <HeaderDefaultAddressName />

            <HeaderAddressTextLinks class="text-links" />

            <HeaderAddressHamburger class="hamburger" />
        </CenterContent>
    </div>
</template>

<style lang="postcss" scoped>
.address-bar {
    color: var(--color-font-dark);
    background-color: var(--color-header-address-bar-bg);
    user-select: none;
    height: var(--size-mobile-address-bar-height);

    & .center-content {
        height: 100%;
        display: flex;
    }

    & .hamburger {
        flex-shrink: 0;
    }
}

@media screen and (max-width: 1190px) {
    :deep(.text-links) {
        margin-right: 0;
        gap: 0;

        & a {
            width: 48px;

            & .base-icon {
                margin: 0;
            }

            & span {
                display: none;
            }
        }

        & .phone {
            & span {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    .address-bar {
        & .center-content {
            height: var(--size-mobile-address-bar-height);
        }

        & .hamburger {
            display: flex;
        }
    }
}

@media screen and (max-width: 440px) {
    .address-bar {
        & .hamburger {
            margin-left: auto;
        }
    }
}
</style>
