<script setup lang="ts">
import Facebook from '@base/assets/icons/facebook.svg?raw';
import Instagram from '@base/assets/icons/instagram.svg?raw';
import Youtube from '@base/assets/icons/youtube.svg?raw';
import Blog from '@base/assets/icons/blog.svg?raw';
import BbbLogo from '@/assets/images/footer/bbb-logo.webp';

const { $cagGoogle } = useNuxtApp();
const route = useRoute();
const appConfig = useAppConfig();
const dealersStore = useDealersStore();
const inventoryStore = useInventoryStore();
const navigationStore = useNavigationStore();
const componentName = getCurrentInstance()?.type.__name ?? '';

const currentYear = new Date().getFullYear();

const areSocialLinks =
    dealersStore.currentDealer.facebook ||
    dealersStore.currentDealer.youtube ||
    dealersStore.currentDealer.blog;

const shouldShowDisclaimer = computed(
    () =>
        route.name === 'index' ||
        route.name === 'search-type-make-model' ||
        route.name === 'detail-type-year-make-model-stock-uniqueArkona'
);

const getCleanCarTitle = (car: InventoryUniqueMakeModel) => {
    return getCleanVehicleTitleArray({
        make: car.make,
        model: car.model,
    }).join(' ');
};

const convertToE164 = (phoneNumber: string) => {
    // Remove non-numeric characters
    const cleaned = phoneNumber.replace(/\D/g, '');

    return `+1${cleaned}`;
};

const salesNumber = convertToE164(dealersStore.currentDealer.phone);
const serviceNumber = convertToE164(dealersStore.currentDealer.servicePhone);
const partsNumber = convertToE164(dealersStore.currentDealer.partsPhone);
</script>

<template>
    <footer>
        <CenterContent padding="40px 2.5rem">
            <div class="links">
                <section>
                    <h3
                        :class="{
                            'theme--vw': appConfig.theme === 'vw',
                        }"
                    >
                        Inventory
                    </h3>

                    <NuxtLink
                        v-for="(car, index) in inventoryStore.uniqueNewCars"
                        :key="index"
                        :to="{
                            name: 'search-type-make-model',
                            params: {
                                type: 'new',
                                make: urlEncode(car.make),
                                model: urlEncode(car.model),
                            },
                        }"
                        class="footer-link"
                    >
                        {{ getCleanCarTitle(car) }}
                        <ClientOnly>
                            {{ inventoryStore.GET_COUNT(car) }}
                        </ClientOnly>
                    </NuxtLink>
                </section>

                <section>
                    <h3
                        :class="{
                            'theme--vw': appConfig.theme === 'vw',
                        }"
                    >
                        Site Links
                    </h3>

                    <template
                        v-for="(chapmanLink, footerIndex) in navigationStore
                            .footer.chapmanLinks"
                        :key="`${chapmanLink.display}-${footerIndex}`"
                    >
                        <NuxtLink
                            :to="chapmanLink.link"
                            :target="
                                isExternalLink(chapmanLink)
                                    ? '_blank'
                                    : undefined
                            "
                            :rel="
                                isExternalLink(chapmanLink)
                                    ? 'noopener'
                                    : undefined
                            "
                            class="footer-link"
                        >
                            {{ chapmanLink.display }}
                        </NuxtLink>
                    </template>
                </section>

                <section>
                    <div class="address">
                        <h3
                            :class="{
                                'theme--vw': appConfig.theme === 'vw',
                            }"
                        >
                            Contact Us
                        </h3>

                        <div>{{ dealersStore.siteName }}</div>

                        <div>{{ dealersStore.currentDealer.address }}</div>

                        <div>
                            {{ dealersStore.currentDealer.city }},
                            {{ dealersStore.currentDealer.state }}
                            {{ dealersStore.currentDealer.zip }}
                        </div>

                        <div class="phone">
                            <div>
                                Sales:
                                <a
                                    :href="`tel:${salesNumber}`"
                                    class="footer-tel"
                                    @click.passive="
                                        $cagGoogle.pushPhoneToDataLayer(
                                            salesNumber,
                                            componentName
                                        )
                                    "
                                >
                                    {{ salesNumber }}
                                </a>
                            </div>

                            <div>
                                Service:
                                <a
                                    :href="`tel:${serviceNumber}`"
                                    class="footer-tel"
                                    @click.passive="
                                        $cagGoogle.pushPhoneToDataLayer(
                                            serviceNumber,
                                            componentName
                                        )
                                    "
                                >
                                    {{ serviceNumber }}
                                </a>
                            </div>

                            <div>
                                Parts:
                                <a
                                    :href="`tel:${partsNumber}`"
                                    class="footer-tel"
                                    @click.passive="
                                        $cagGoogle.pushPhoneToDataLayer(
                                            partsNumber,
                                            componentName
                                        )
                                    "
                                >
                                    {{ partsNumber }}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="member">
                        <h3
                            :class="{
                                'theme--vw': appConfig.theme === 'vw',
                            }"
                        >
                            Member Of
                        </h3>

                        <NuxtLink
                            v-for="(chapmanLink, memberIndex) in navigationStore
                                .footer.memberLinks"
                            :key="`${chapmanLink.display}-${memberIndex}`"
                            :to="chapmanLink.link"
                            class="footer-link"
                        >
                            {{ chapmanLink.display }}
                        </NuxtLink>
                    </div>

                    <div v-if="areSocialLinks" class="social">
                        <h3
                            :class="{
                                'theme--vw': appConfig.theme === 'vw',
                            }"
                        >
                            Social
                        </h3>

                        <a
                            :href="dealersStore.currentDealer.facebook"
                            target="_blank"
                            rel="noopener"
                            title="Facebook"
                            aria-label="Facebook"
                        >
                            <BaseIcon
                                :icon-html="Facebook"
                                class="social-icon"
                            />
                        </a>

                        <a
                            v-if="dealersStore.currentDealer.instagram"
                            :href="dealersStore.currentDealer.instagram"
                            target="_blank"
                            rel="noopener"
                            title="Instagram"
                            aria-label="Instagram"
                        >
                            <BaseIcon
                                :icon-html="Instagram"
                                class="social-icon"
                            />
                        </a>

                        <a
                            v-if="dealersStore.currentDealer.youtube"
                            :href="dealersStore.currentDealer.youtube"
                            target="_blank"
                            rel="noopener"
                            title="YouTube"
                            aria-label="YouTube"
                        >
                            <BaseIcon
                                :icon-html="Youtube"
                                class="social-icon"
                            />
                        </a>

                        <a
                            v-if="dealersStore.currentDealer.blog"
                            :href="dealersStore.currentDealer.blog"
                            target="_blank"
                            rel="noopener"
                            title="Blog"
                            aria-label="Blog"
                        >
                            <BaseIcon :icon-html="Blog" class="social-icon" />
                        </a>
                    </div>

                    <img
                        v-if="appConfig.footerOptions.showBbbSeal"
                        :src="BbbLogo"
                        alt="BBB Accredited Business"
                        width="300"
                        height="114"
                        class="bbb-logo"
                        loading="lazy"
                    />
                </section>
            </div>

            <div id="disclaimer" class="disclaimer">
                <div>&copy; {{ currentYear }} Chapman Automotive Group</div>

                <div
                    v-for="(disclaimer, disclaimerIndex) in appConfig
                        .footerOptions.disclaimers"
                    :key="disclaimerIndex"
                >
                    {{ disclaimer }}
                </div>

                <div v-if="shouldShowDisclaimer">
                    {{ dealersStore.currentDealerDisclaimer }}
                </div>
            </div>
        </CenterContent>
    </footer>
</template>

<style lang="postcss" scoped>
footer {
    background-color: var(--color-footer-bg, #000);
    color: var(--color-font-light);
}

.links {
    display: flex;
    justify-content: space-between;
    line-height: 1.4;

    & h3 {
        text-transform: uppercase;
        margin-bottom: 10px;

        &.theme--vw {
            font-family: var(--font-family-vw-head-regular);
        }
    }

    & a {
        display: block;
        color: var(--color-font-light);

        &:hover,
        &:focus {
            color: var(--color-accent);
        }
    }

    & section {
        margin-bottom: 30px;
        padding: 0 5px;

        & .address {
            & .phone {
                padding-top: 10px;

                & div {
                    display: flex;
                }

                & .footer-tel {
                    padding-left: 5px;
                }
            }
        }

        & .member {
            padding-top: 20px;
        }

        & .social {
            padding-top: 20px;

            & h3 {
                margin-bottom: 15px;
            }

            & a {
                fill: var(--color-font-light);
                display: inline-block;
                margin-right: 10px;
                width: 25px;

                & .social-icon {
                    --base-icon-size: 25px;
                    --base-icon-stroke: none;
                }

                &:hover,
                &:focus {
                    fill: var(--color-accent);
                }
            }
        }
    }

    & .bbb-logo {
        margin: 1.5rem auto 0;
        max-width: 150px;
        width: 100%;
        height: auto;
    }
}

.disclaimer {
    line-height: 1.4;
    color: #aaa;

    & * {
        margin-top: 10px;
    }
}

/*
 * Prevent BackToTop and ChatBubble from covering footer text.
 * 1380px is when BackToTop starts to touch footer content.
 */
@media screen and (max-width: 1380px) {
    footer {
        padding-bottom: 2.5rem;
    }
}

@media screen and (max-width: 780px) {
    & .links {
        & .footer-link,
        & .footer-tel {
            padding: 0.5rem 0;
        }

        & section {
            & .address {
                & .phone {
                    & div {
                        align-items: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    & .links {
        flex-direction: column;

        & section {
            text-align: center;
        }

        & .phone {
            & div {
                justify-content: center;
            }
        }
    }
}

@media print {
    * {
        display: none;
    }
}
</style>
