<script setup lang="ts">
const props = defineProps<{
    chapmanLink: ChapmanLink;
}>();

const navigationStore = useNavigationStore();
const emit = defineEmits<{ click: [value: string] }>();

const closeNav = () => {
    const element = document.activeElement as HTMLElement;
    element.blur();

    emit('click', '');

    if (navigationStore.header.open) {
        navigationStore.TOGGLE_NAV();
    }
};

const openMenu = computed(
    () => props.chapmanLink.display === navigationStore.header.openMenu
);
</script>

<template>
    <div
        class="nav-top-level"
        :class="{ 'sub-open': navigationStore.header.sub }"
    >
        <HeaderNavLink
            :chapman-link="props.chapmanLink"
            :show-arrows="true"
            @toggle-sub-nav="emit('click', props.chapmanLink.display)"
            @close="closeNav"
        />

        <HeaderNavSubMenu
            v-if="props.chapmanLink.children"
            v-model:open="openMenu"
            :chapman-link="props.chapmanLink"
            class="nav-sub-menu"
            @close-sub-menu="emit('click', props.chapmanLink.display)"
            @close="closeNav"
        />
    </div>
</template>

<style lang="postcss" scoped>
.nav-top-level {
    display: flex;
    flex: none;

    &:has(.open) {
        & :deep(button) {
            border-bottom: 5px solid var(--color-accent);

            & .arrow-icon-wrap {
                transform: rotate(-90deg);
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    .nav-sub-menu {
        position: fixed;
        inset: 0;
        overflow-y: auto;

        &.open {
            transform: translateX(0);
            opacity: 1;
        }

        & :deep(.mobile-controls) {
            display: flex;
        }
    }

    .nav-top-level {
        & .nav-link {
            padding: 15px;
            background-color: var(--color-light-gray);
            flex: auto;
            text-align: center;
            margin: 10px;
            cursor: pointer;
            transition: var(--transition-base);
            color: var(--color-font-dark);
            border: none;
            height: auto;

            &:hover,
            &:focus {
                background-color: var(--color-accent);
                color: var(--color-text-on-accent);
                border-bottom: none;
            }

            & :deep(.arrow-icon-wrap) {
                & .base-icon {
                    &.mobile-only {
                        display: block;
                    }

                    &.desktop-only {
                        display: none;
                    }
                }
            }
        }

        &.sub-open {
            & .nav-link {
                display: none;
            }
        }
    }
}
</style>
